const API_CMS = process.env.VUE_APP_CMS || "";
const API_OPEN = process.env.VUE_APP_OPEN_API || "";
const ENABLE_FPS = process.env.VUE_APP_ENABLE_FPS == "true" ? true : false;
const SHOW_TOOLBAR = process.env.VUE_APP_SHOW_TOOLBAR == "true" ? true : false;

export default {
    API_CMS,
    API_OPEN,
    ENABLE_FPS,
    SHOW_TOOLBAR
};
