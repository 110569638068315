import Vue from "vue";

const keysConvertNumber = ["Epoch", "H", "M", "Node", "Orbital_period", "Peri", "Tp", "a", "e", "i", "n", "q", "rms"]

const convertHMS2Decimal = (h, m, s, isRa = false) => {
    if (h == null || m == null || s == null) {
        return undefined;
    }
    const decimal = Number(h) + Number(m) / 60 + Number(s) / 3600;
    if (isRa) {
        return decimal * 15;
    }
    return decimal;
}

const getModelData = (information, model) => {
    if (model === 'mpc_asteroid') {
        const newInfo = {};
        Object.keys(information).reduce((prev, currentKey) => {
            prev[currentKey] = keysConvertNumber.includes(currentKey) ? Number(information[currentKey]) : information[currentKey];
            return prev;
        }, newInfo);
        return newInfo;
    }
    const { Vmag, de, ra } = information;
    if (Vmag && de && ra) {
        return {
            Vmag: Number(Vmag),
            de: Number(de),
            ra: Number(ra)
        }
    }
    const coords = information.coord?.split(" ") || [];
    if (!coords.length) {
        return undefined;
    }
    const [raH, raM, raS, deH, deM, deS] = coords;
    const vmag = information.m1 || "";
    return {
        Vmag: Number(String(vmag).replace(",", ".") || 1),
        ra: convertHMS2Decimal(raH, raM, raS, true),
        de: convertHMS2Decimal(deH, deM, deS),
    }
}

export const getObjByInformation = (ss) => {
    if (!ss.information) {
        return undefined;
    }

    if (typeof ss.information === 'string') {
        ss.information = JSON.parse(ss.information);
    }

    const modelData = getModelData(ss.information, ss.model);
    if (!modelData) {
        return undefined;
    }

    const newSS = {
        model_data: modelData,
        short_name: ss.name,
        names: ss.names,
        model: ss.model === 'plan' ? 'pla' : ss.model,
        types: ss.types,
        sub_name: ss.nameRegister
    }
    const $stel = Vue.prototype.$stel;
    const obj = $stel.createObj(newSS.model, newSS);
    Vue.prototype.$selectionLayer.add(obj)
    return obj;
}