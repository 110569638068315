<template>
  <v-main>
    <v-container>
      <div v-if="isLoading" style="display:flex; justify-content: center;">
        <v-progress-circular
          indeterminate
          v-bind:size="20"
          v-bind:width="2"
          class="grey--text"
        />
      </div>
      <div v-html="content"></div>
    </v-container>
  </v-main>
</template>

<script>
import LEGAL from "@/constants/legal";
import ENV from "@/constants/environment";
import GuiLoader from "@/components/gui-loader.vue";

export default {
  data() {
    return {
      content: "",
      isLoading: false,
      LoaderComponent: "GuiLoader"
    };
  },
  components: { GuiLoader },
  methods: {
    fetchData() {
      const that = this;
      that.isLoading = true;
      fetch(`${ENV.API_CMS}/api/setting/legal/Terms`)
        .then(response => response.json())
        .then(data => {
          that.content = data?.data?.value || LEGAL.TOS;
          that.isLoading = false;
        })
        .catch(err => {
          console.error("ERROR: Fetching TOS", err);
          that.isLoading = false;
        });
    }
  },
  mounted: function() {
    this.fetchData();
  }
};
</script>

<style>
* {
  color: #fff !important;
  font-family: ChakraPetch-Regular !important;
}
</style>
