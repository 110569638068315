<template>
  <v-app
    v-bind:style="{ background: bgApp, 'pointer-events': pointerEvents }"
    class="us-none"
  >
    <v-navigation-drawer v-model="nav" app stateless width="300">
      <v-layout column fill-height>
        <v-list dense>
          <template v-for="(item, i) in menuItems">
            <template
              v-if="$store.state[item.store_show_menu_item] === false"
            ></template>
            <v-subheader
              v-else-if="item.header"
              v-text="item.header"
              class="grey--text text--darken-1"
              :key="i"
            />
            <v-divider class="divider_menu" v-else-if="item.divider" :key="i" />
            <v-list-item
              v-else-if="item.switch"
              @click.stop="toggleStoreValue(item.store_var_name)"
              :key="i"
            >
              <v-list-item-action>
                <v-switch
                  value
                  :input-value="getStoreValue(item.store_var_name)"
                  label=""
                ></v-switch>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-else>
              <v-list-item
                v-if="item.link"
                target="_blank"
                rel="noopener"
                :href="item.link"
                :key="i"
              >
                <v-list-item-icon
                  ><v-icon>{{ item.icon }}</v-icon></v-list-item-icon
                >
                <v-list-item-title v-text="item.title" />
                <v-icon disabled>mdi-open-in-new</v-icon>
              </v-list-item>
              <v-list-item
                v-else-if="item.footer === undefined"
                @click.stop="toggleStoreValue(item.store_var_name)"
                :key="i"
              >
                <v-list-item-icon
                  ><v-icon>{{ item.icon }}</v-icon></v-list-item-icon
                >
                <v-list-item-title v-text="item.title" />
              </v-list-item>
            </template>
          </template>
        </v-list>
        <template v-for="(item, i) in menuComponents">
          <component :is="item" :key="i"></component>
        </template>
        <v-spacer></v-spacer>
        <v-list dense>
          <v-divider class="divider_menu" />
          <template v-for="(item, i) in menuItems">
            <v-list-item
              v-if="item.footer"
              @click.stop="toggleStoreValue(item.store_var_name)"
              :key="i"
            >
              <v-list-item-icon
                ><v-icon>{{ item.icon }}</v-icon></v-list-item-icon
              >
              <v-list-item-title v-text="item.title" />
            </v-list-item>
          </template>
        </v-list>
      </v-layout>
    </v-navigation-drawer>

    <v-main>
      <v-container class="fill-height" fluid style="padding: 0">
        <div
          id="stel"
          v-bind:class="{ right_panel: $store.state.showSidePanel }"
        >
          <div style="position: relative; width: 100%; height: 100%">
            <div
              v-if="$store.state.showFPS"
              style="
                position: absolute;
                top: 20px;
                right: 5px;
                user-select: none;
                font-size: 10px;
              "
            >
              FPS:
              {{ $store.state.stel ? $store.state.stel.fps.toFixed(0) : "?" }}
            </div>
            <component v-bind:is="guiComponent"></component>
            <canvas
              id="stel-canvas"
              class="canvas-bg"
              ref="stelCanvas"
              v-bind:class="classNameCanvas"
            ></canvas>
          </div>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import _ from "lodash";
import Gui from "@/components/gui.vue";
import GuiLoader from "@/components/gui-loader.vue";
import swh from "@/assets/sw_helpers.js";
import Moment from "moment";
import env from "./constants/environment";

export default {
  data(context) {
    return {
      menuItems: [
        {
          title: this.$t("View Settings"),
          icon: "mdi-settings",
          store_var_name: "showViewSettingsDialog",
          store_show_menu_item: "showViewSettingsMenuItem",
        },
        {
          title: this.$t("Planets Tonight"),
          icon: "mdi-panorama-fisheye",
          store_var_name: "showPlanetsVisibilityDialog",
          store_show_menu_item: "showPlanetsVisibilityMenuItem",
        },
        { divider: true },
      ]
        .concat(this.getPluginsMenuItems())
        .concat([
          {
            title: this.$t("Data Credits"),
            footer: true,
            icon: "mdi-copyright",
            store_var_name: "showDataCreditsDialog",
          },
        ]),
      menuComponents: [].concat(this.getPluginsMenuComponents()),
      guiComponent: "GuiLoader",
      startTimeIsSet: false,
      initDone: false,
      dataSourceInitDone: false,
      bgApp: "#000",
      classNameCanvas: "",
      initObserve: null,
      timer: 0,
      pointerEvents: "inherit",
    };
  },
  components: { Gui, GuiLoader },
  methods: {
    processEventData: function (message) {
      const that = this;
      try {
        if (message && message.data) {
          const data = JSON.parse(message.data);
          switch (data.type) {
            case "noti_owner":
              const newStars = this.$stel.core.stars.owners?.map((item) => ({
                ...item,
                t:
                  item?.a?.toLowerCase() == data?.data?.address?.toLowerCase()
                    ? "true"
                    : "false",
              }));

              this.$stel.core.stars.owners = [newStars];
              break;
            case "set_location":
              const lat = data.data.lat;
              const lng = data.data.lng;
              const elev = data.data.elev || 0;
              const pos = {
                lat: Number(lat),
                lng: Number(lng),
                alt: Number(elev),
                accuracy: 1,
              };
              this.$store.commit("setCurrentLocation", pos);
              break;
            case "gyroscope_mode":
              this.$store.state.gyroscopeMode = data.data;
              if (this.$store.state.gyroscopeMode) {
                if (this.$stel.core.selection) {
                  this.$root.$emit("un_selected_star");
                }
                // if (this.$stel.core.fov !== this.$stel._custom.maxZoom) {
                //   this.$stel.zoomTo(this.$stel._custom.maxZoom, 0.5);
                // }
              }

              this.classNameCanvas = "";
              this.bgApp = "#000";
              break;
            case "handle_orientation":
              this.handleOrientation(data.data);
              break;
            case "zoom_in_star":
              this.pointerEvents = "none";
              setTimeout(() => {
                that.pointerEvents = "inherit";
              }, 2000);
              this.$root.$emit("zoom_in_star", data.name || "");
              break;
            case "zoom_out_star":
              this.pointerEvents = "inherit";
              this.$root.$emit("zoom_out_star");
              break;
            case "ar_mode":
              this.bgApp = "transparent";
              this.classNameCanvas = "none-bg";
              break;
            case "landscapes":
              this.$stel.core.landscapes.visible =
                typeof data.data === "boolean" ? data.data : true;
              break;
            case "refetch_list":
              that.refetchOwners(data.data);
              break;
            case "search_star":
              if (this.$stel.core.selection) {
                this.$store.commit("setSelection", 0);
              }
              if (!data.data) {
                return;
              }
              const obj = swh.skySource2SweObjFromWebview(data.data, true);
              if (!obj) {
                return;
              }
              swh.setSweObjAsSelection(obj);
              break;
            case "search_star_by_hip":
              if (this.$stel.core.selection) {
                this.$store.commit("setSelection", 0);
              }
              if (!data.data) {
                return;
              }
              const objByHIP = swh.skySource2SweObjByHIP(data.data);
              if (!objByHIP) {
                return;
              }
              swh.setSweObjAsSelection(objByHIP);
              break;
            case "get_star_info_by_id":
              const {
                starId = "",
                figures = "",
                json_data = null,
              } = data?.data;
              if (!starId || !json_data || !figures) {
                return;
              }
              const dataSend = { starId, figures, summary: "" };
              swh
                .getSkySourceSummaryFromWikipedia(json_data, null)
                .then((dataWiki) => {
                  dataSend.summary = swh.cleanDataFromWiki(dataWiki);
                  window.ReactNativeWebView?.postMessage(
                    JSON.stringify({
                      type: "store_star_info_by_id",
                      data: dataSend,
                    })
                  );
                })
                .catch((err) => {
                  window.ReactNativeWebView?.postMessage(
                    JSON.stringify({
                      type: "store_star_info_by_id",
                      data: dataSend,
                    })
                  );
                });
              break;
            default:
              break;
          }
        }
      } catch (error) {
        // console.log('Err processEventData')
      }
    },
    handleOrientation: function (data) {
      if (this.$store.state.gyroscopeMode === false) {
        this.initObserve = null;
        return;
      }
      if (!this.initObserve) {
        if (this.timer) {
          return;
        }
        this.$stel.movePitch(data.pitch);
        this.$stel.moveYaw(data.yaw);
        const that = this;
        this.timer = setTimeout(() => {
          that.initObserve = {
            yaw: data.yaw,
            pitch: data.pitch,
          };
          that.timer = 0;
        }, 1000);
        return;
      }
      // this.$stel.zoomTo(0.9, 1)
      this.$stel.core.observer.yaw = data.yaw;
      this.$stel.core.observer.pitch = data.pitch;
    },
    getPluginsMenuItems: function () {
      let res = [];
      for (const i in this.$stellariumWebPlugins()) {
        const plugin = this.$stellariumWebPlugins()[i];
        if (plugin.menuItems) {
          res = res.concat(plugin.menuItems);
        }
      }
      return res;
    },
    getPluginsMenuComponents: function () {
      let res = [];
      for (const i in this.$stellariumWebPlugins()) {
        const plugin = this.$stellariumWebPlugins()[i];
        if (plugin.menuComponents) {
          res = res.concat(plugin.menuComponents);
        }
      }
      return res;
    },
    toggleStoreValue: function (storeVarName) {
      this.$store.commit("toggleBool", storeVarName);
    },
    getStoreValue: function (storeVarName) {
      return _.get(this.$store.state, storeVarName);
    },
    setStateFromQueryArgs: function () {
      // Check whether the observing panel must be displayed
      this.$store.commit("setValue", {
        varName: "showSidePanel",
        newValue: this.$route.path.startsWith("/p/"),
      });

      // Set the core's state from URL query arguments such
      // as date, location, view direction & fov
      var that = this;

      if (!this.initDone) {
        this.$stel.core.time_speed = 1;
        let d = new Date();
        if (this.$route.query.date) {
          d = new Moment(this.$route.query.date).toDate();
          console.log("Moment", d);
          this.$stel.core.observer.utc = d.getMJD();
          this.startTimeIsSet = true;
        }

        if (this.$route.query.lng && this.$route.query.lat) {
          const pos = {
            lat: Number(this.$route.query.lat),
            lng: Number(this.$route.query.lng),
            alt: this.$route.query.elev ? Number(this.$route.query.elev) : 0,
            accuracy: 1,
          };
          swh.geoCodePosition(pos, that).then(
            (loc) => {
              that.$store.commit("setCurrentLocation", loc);
            },
            (error) => {
              console.log(error);
            }
          );
        }
        this.$stel.core.observer.yaw = this.$route.query.az
          ? (Number(this.$route.query.az) * Math.PI) / 180
          : 0;
        this.$stel.core.observer.pitch = this.$route.query.alt
          ? (Number(this.$route.query.alt) * Math.PI) / 180
          : (-0.4 * Math.PI) / 180;
        this.$stel.core.fov = this.$route.query.fov
          ? (Number(this.$route.query.fov) * Math.PI) / 180
          : (35 * Math.PI) / 180; //Set default fov < max_fov(50)proj_stereographic.c

        this.initDone = true;
      }

      if (this.$route.path.startsWith("/skysource/")) {
        const name = decodeURIComponent(this.$route.path.substring(11));
        console.log("Will select object: " + name);
        return swh.lookupSkySourceByName(name).then(
          (ss) => {
            if (!ss) {
              return;
            }
            let obj = swh.skySource2SweObj(ss);
            if (!obj) {
              obj = this.$stel.createObj(ss.model, ss);
              this.$selectionLayer.add(obj);
            }
            if (!obj) {
              console.warning("Can't find object in SWE: " + ss.names[0]);
            }
            swh.setSweObjAsSelection(obj);
          },
          (err) => {
            console.log(err);
            console.log("Couldn't find skysource for name: " + name);
          }
        );
      }
    },
    refetchOwners: function (data) {
      if (!data) {
        return;
      }
      const that = this;
      const newData = {
        stars: [],
        minor_planets: [],
        planets: [],
      };
      const changeOwners = {
        stars: [],
        minor_planets: [],
        planets: [],
      };
      if (Array.isArray(data)) {
        data.forEach((d) => {
          let indexOf = -1;
          const dataInsert = {
            i: d.isPioneer ? "true" : "",
            o: d.ownerName,
            n: d.name.trim(),
            s: d.names,
            a: d.address,
            m: d.market,
          };
          let key = "stars";
          if (d.model === "star") {
            dataInsert.h = d.hip;
            const change_owners = that.$stel.core.stars.change_owners || [];
            if (change_owners && Array.isArray(change_owners)) {
              indexOf = change_owners?.findIndex((o) => o.h === d.hip);
            }
          } else {
            if (d.model === "mpc_asteroid") {
              key = "minor_planets";
            } else {
              key = "planets";
            }
            const change_owners = that.$stel.core[key].change_owners || [];
            if (change_owners && Array.isArray(change_owners)) {
              indexOf = change_owners?.findIndex(
                (o) => o.n === d.name.trim()
              );
            }
          }
          if (d.changeOwner) {
            if (indexOf > -1) {
              // Just replace item if already existed
              const change_owners = that.$stel.core[key].change_owners;
              change_owners[indexOf] = {
                ...change_owners[indexOf],
                ...dataInsert,
              };
              that.$stel.core[key].change_owners = [change_owners];
            } else {
              changeOwners[key].push(dataInsert);
            }
          } else {
            newData[key].push(dataInsert);
          }
        });
      }
      const listKey = ["stars", "minor_planets", "planets"];
      listKey.forEach((key) => {
        if (newData[key].length) {
          that.$stel.core[key].new_owners = [newData[key]];
        }
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: key, data: newData[key].length })
        );
        if (changeOwners[key].length) {
          that.$stel.core[key].change_owners = that.$stel.core[key]
            .change_owners?.length
            ? [that.$stel.core[key].change_owners.concat(changeOwners[key])]
            : [changeOwners[key]];
        }
      });
    },
  },
  computed: {
    nav: {
      get: function () {
        return this.$store.state.showNavigationDrawer;
      },
      set: function (v) {
        if (this.$store.state.showNavigationDrawer !== v) {
          this.$store.commit("toggleBool", "showNavigationDrawer");
        }
      },
    },
    storeCurrentLocation: function () {
      return this.$store.state.currentLocation;
    },
    fov() {
      return this.$store.state.stel?.fov;
    },
  },
  watch: {
    storeCurrentLocation: function (loc) {
      const DD2R = Math.PI / 180;
      this.$stel.core.observer.latitude = loc.lat * DD2R;
      this.$stel.core.observer.longitude = loc.lng * DD2R;
      this.$stel.core.observer.elevation = loc.alt;

      // At startup, we need to wait for the location to be set before deciding which
      // startup time to set so that it's night time.
      // if (!this.startTimeIsSet) {
      // this.$stel.core.observer.utc = swh.getTimeAfterSunset(this.$stel)
      // this.startTimeIsSet = true
      // }
      // Init of time and date is complete
      this.$store.commit("setValue", {
        varName: "initComplete",
        newValue: true,
      });
    },
    $route: function () {
      // react to route changes...
      this.setStateFromQueryArgs();
    },
    dataSourceInitDone: function (val) {
      if (val && window.ReactNativeWebView) {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: "init_map", data: "done" })
        );
      }
    },
    fov() {
      const fovDeg = (this.$stel?.core?.fov * 180) / Math.PI;
      this.classNameCanvas = fovDeg <= 15 ? "trans" : "";
      this.$stel.core.landscapes.visible = !(fovDeg <= 15);
    },
  },
  mounted: async function () {
    var that = this;
    for (const i in this.$stellariumWebPlugins()) {
      const plugin = this.$stellariumWebPlugins()[i];
      if (plugin.onAppMounted) {
        plugin.onAppMounted(that);
      }
    }

    const calls = [];
    //Get Register Names of the Planets
    calls.push(fetch(`${env.API_OPEN}/stars/plan/register-names`));

    //Get Register Names of the Stars
    calls.push(fetch(`${env.API_OPEN}/stars/star/register-names`));

    //Get Register Names of the Asteroids
    calls.push(fetch(`${env.API_OPEN}/stars/mpc_asteroid/register-names`));

    //Get the Planets has owner
    calls.push(fetch(`${env.API_OPEN}/stars/plan/has-owner`));

    //Get the Stars has owner
    calls.push(fetch(`${env.API_OPEN}/stars/star/has-owner`));

    //Get the Asteroids has owner
    calls.push(fetch(`${env.API_OPEN}/stars/mpc_asteroid/has-owner`));
    const [
      planetsResponse,
      starsResponse,
      asteroidsResponse,
      ownerPlanetsResponse,
      ownerStarsResponse,
      ownerAsteroidsResponse,
    ] = await Promise.all(calls);

    import("@/assets/js/stellarium-web-engine.wasm").then((f) => {
      // Initialize the StelWebEngine viewer singleton
      // After this call, the StelWebEngine state will always be available in vuex store
      // in the $store.stel object in a reactive way (useful for vue components).
      // To modify the state of the StelWebEngine, it's enough to call/set values directly on the $stel object
      try {
        swh.initStelWebEngine(
          that.$store,
          f.default,
          that.$refs.stelCanvas,
          function () {
            // Start auto location detection (even if we don't use it)
            // swh.getGeolocation().then(p => swh.geoCodePosition(p, that)).then((loc) => {
            //   that.$store.commit('setAutoDetectedLocation', loc)
            // }, (error) => { console.log(error) })
            // that.$stel.setFont(
            //   "regular",
            //   process.env.BASE_URL + "fonts/NotoSansKR-Regular.otf",
            //   1.38
            // );
            // that.$stel.setFont(
            //   "bold",
            //   process.env.BASE_URL + "fonts/NotoSansKR-Bold.otf",
            //   1.38
            // );
            that.$stel.core.atmosphere.visible = false;
            that.$stel.core.constellations.lines_visible = true;
            that.$stel.core.constellations.images_visible = true;

            that.setStateFromQueryArgs();
            that.guiComponent = "Gui";
            for (const i in that.$stellariumWebPlugins()) {
              const plugin = that.$stellariumWebPlugins()[i];
              if (plugin.onEngineReady) {
                plugin.onEngineReady(that);
              }
            }

            if (!that.dataSourceInitDone) {
              // Set all default data sources
              const core = that.$stel.core;
              core.stars.addDataSource({
                url: process.env.BASE_URL + "skydata/stars",
              });

              //show star name offset from center
              core.stars.hints_mag_offset = (80 * Math.PI) / 180;

              // //Limit mag show stars
              core.display_limit_mag = 10;
              // core.star_linear_scale = 1.2;

              //Increase brightness of star
              // core.bortle_index = 1;

              // Allow to specify a custom path for sky culture data
              if (that.$route.query.sc) {
                const key = that.$route.query.sc.substring(
                  that.$route.query.sc.lastIndexOf("/") + 1
                );
                core.skycultures.addDataSource({
                  url: that.$route.query.sc,
                  key: key,
                });
                core.skycultures.current_id = key;
              } else {
                core.skycultures.addDataSource({
                  url: process.env.BASE_URL + "skydata/skycultures/western",
                  key: "western",
                });
              }

              core.dsos.addDataSource({
                url: process.env.BASE_URL + "skydata/dso",
              });
              core.landscapes.addDataSource({
                url: process.env.BASE_URL + "skydata/landscapes/milky-way",
                key: "milky-way",
              });
              core.milkyway.addDataSource({
                url: process.env.BASE_URL + "skydata/surveys/milkyway",
              });
              core.minor_planets.addDataSource({
                url: process.env.BASE_URL + "skydata/mpcorb.dat",
                key: "mpc_asteroids",
              });
              core.planets.addDataSource({
                url: process.env.BASE_URL + "skydata/surveys/sso/moon",
                key: "moon",
              });
              core.planets.addDataSource({
                url: process.env.BASE_URL + "skydata/surveys/sso/sun_new",
                key: "sun",
              });
              core.planets.addDataSource({
                url: process.env.BASE_URL + "skydata/surveys/sso/jupiter",
                key: "jupiter",
              });
              core.planets.addDataSource({
                url: process.env.BASE_URL + "skydata/surveys/sso/mars",
                key: "mars",
              });
              core.planets.addDataSource({
                url: process.env.BASE_URL + "skydata/surveys/sso/venus",
                key: "venus",
              });
              core.planets.addDataSource({
                url: process.env.BASE_URL + "skydata/surveys/sso/saturn",
                key: "saturn",
              });
              core.planets.addDataSource({
                url: process.env.BASE_URL + "skydata/surveys/sso/mercury",
                key: "mercury",
              });
              core.planets.addDataSource({
                url:
                  process.env.BASE_URL + "skydata/surveys/sso/planet_default",
                key: "default",
              });
              core.comets.addDataSource({
                url: process.env.BASE_URL + "skydata/CometEls.txt",
                key: "mpc_comets",
              });
              core.satellites.addDataSource({
                url: process.env.BASE_URL + "skydata/tle_satellite.jsonl.gz",
                key: "jsonl/sat",
              });

              //Register name
              if (!planetsResponse.ok) {
                throw planetsResponse.body;
              }
              planetsResponse.json().then((planets) => {
                core.planets.register_names = [planets.data];
              });

              if (!starsResponse.ok) {
                throw starsResponse.body;
              }
              starsResponse.json().then((stars) => {
                core.stars.register_names = [stars.data];
              });

              if (!asteroidsResponse.ok) {
                throw asteroidsResponse.body;
              }
              asteroidsResponse.json().then((asteroids) => {
                core.minor_planets.register_names = [asteroids.data];
              });

              //Owners
              if (!ownerPlanetsResponse.ok) {
                throw ownerPlanetsResponse.body;
              }
              ownerPlanetsResponse.json().then((ownerPlanets) => {
                core.planets.owners = [ownerPlanets.data];
              });

              if (!ownerStarsResponse.ok) {
                throw ownerStarsResponse.body;
              }
              ownerStarsResponse.json().then((ownerStars) => {
                core.stars.owners = [ownerStars.data];
              });

              if (!ownerAsteroidsResponse.ok) {
                throw ownerAsteroidsResponse.body;
              }
              ownerAsteroidsResponse.json().then((ownerAsteroids) => {
                core.minor_planets.owners = [ownerAsteroids.data];
              });

              that.$stel._custom = {
                maxZoom: 0.8,
                minZoom: 0.0005,
              };
              // window.stel = that.$stel;
              //Set default fov
              // that.$stel.core.fov = that.$stel._custom.maxZoom;
              core.landscapes.opacity = 0.4;
              core.landscapes.fog_visible = false;
              core.satellites.hints_mag_offset = 10;
              that.dataSourceInitDone = true;
            }
          }
        );
      } catch (e) {
        this.$store.commit("setValue", {
          varName: "wasmSupport",
          newValue: false,
        });
      }
    });
    //IOS Working
    window.addEventListener("message", (message) => {
      try {
        this.processEventData(message);
      } catch (error) {
        console.log("Err window");
      }
    });
    //Android Working
    document.addEventListener("message", (message) => {
      try {
        this.processEventData(message);
      } catch (error) {
        console.log("Err document");
      }
    });
  },
};
</script>

<style>
a {
  color: #82b1ff;
}

a:link {
  text-decoration-line: none;
}

.divider_menu {
  margin-top: 8px;
  margin-bottom: 8px;
}

html {
  overflow-y: visible;
}

html,
body,
#app {
  overflow-y: visible !important;
  overflow-x: visible;
  position: fixed !important;
  width: 100%;
  height: 100%;
  padding: 0 !important;
  font-size: 14px;
}

#toolbar-image {
  display: none;
}

.fullscreen {
  overflow-y: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0 !important;
}

.click-through {
  pointer-events: none;
}

.get-click {
  pointer-events: all;
}

.dialog {
  background: transparent;
}

.menu__content {
  background-color: transparent !important;
}

#stel {
  height: 100%;
  width: 100%;
  position: absolute;
}
#stel-canvas {
  z-index: -10;
  width: 100%;
  height: 100%;
}

.right_panel {
  padding-right: 400px;
}

.v-btn {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.us-none {
  user-select: none;
}

.v-application--wrap {
  min-height: 100% !important;
}

.canvas-bg {
  background-color: #091018;
  transition: background-color 1000ms ease-in-out;
  -webkit-transition: background-color 1000ms ease-in-out;
}

.canvas-bg.none-bg {
  background-color: transparent;
}

.canvas-bg.trans {
  background-color: #000000;
}

@font-face {
  font-family: "ChakraPetch-Bold";
  src: local("ChakraPetch-Bold"),
    url(./assets/fonts/ChakraPetch-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "ChakraPetch-BoldItalic";
  src: local("ChakraPetch-BoldItalic"),
    url(./assets/fonts/ChakraPetch-BoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "ChakraPetch-Italic";
  src: local("ChakraPetch-Italic"),
    url(./assets/fonts/ChakraPetch-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "ChakraPetch-Light";
  src: local("ChakraPetch-Light"),
    url(./assets/fonts/ChakraPetch-Light.ttf) format("truetype");
}
@font-face {
  font-family: "ChakraPetch-LightItalic";
  src: local("ChakraPetch-LightItalic"),
    url(./assets/fonts/ChakraPetch-LightItalic.ttf) format("truetype");
}
@font-face {
  font-family: "ChakraPetch-Medium";
  src: local("ChakraPetch-Medium"),
    url(./assets/fonts/ChakraPetch-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "ChakraPetch-Regular";
  src: local("ChakraPetch-Regular"),
    url(./assets/fonts/ChakraPetch-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "ChakraPetch-SemiBold";
  src: local("ChakraPetch-SemiBold"),
    url(./assets/fonts/ChakraPetch-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "ChakraPetch-SemiBoldItalic";
  src: local("ChakraPetch-SemiBoldItalic"),
    url(./assets/fonts/ChakraPetch-SemiBoldItalic.ttf) format("truetype");
}
</style>
